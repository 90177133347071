import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-sign-up-form',
    templateUrl: './sign-up-form.component.html',
    styleUrls: ['./sign-up-form.component.scss'],
})
export class SignUpFormComponent implements OnInit {

    formGroup: FormGroup;
    errorMessage: string;
    isProcessing: boolean;

    constructor(
        private authService: AuthenticationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            name: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
            confirmPassword: new FormControl('', [Validators.required, this.checkPasswords.bind(this)]),
            agreePrivacyPolicy: new FormControl(false, Validators.required)
        });
    }

    signUp(): void {
        if (this.formGroup.invalid) {
            for (const form of Object.keys(this.formGroup.controls)) {
                this.formGroup.controls[form].markAsTouched();
            }
            return;
        }

        this.isProcessing = true;

        this.authService.signUp(
            this.formGroup.controls.email.value,
            this.formGroup.controls.password.value,
            this.formGroup.controls.name.value
        ).pipe(
            finalize(() => {
                this.isProcessing = false;
            })
        ).subscribe({
            complete: () => {
                this.formGroup.reset();
                this.router.navigate(['/login']);
            },
            error: (e) => {
                this.errorMessage = this.authService.translateErrorMessage(e);
            }
        });
    }

    checkPasswords(control: FormControl): ValidationErrors {
        const password = this.formGroup?.get('password').value;
        const confirmPassword = control.value;

        return password === confirmPassword ? null : { notSame: '비밀번호가 일치하지 않습니다.' };
    }

    openPrivacyPolicy(): void{
        window.open('https://docs.google.com/document/d/e/2PACX-1vQHpeZCyQDgS1MSDd3Imv1t8MZP5aZDNMBCoOJbF_RxDtU-ZXYwUHs2q6JXug7N9viWOvHht73T0ZJu/pub');
    }

    openTermsOfService(): void{
        window.open('https://docs.google.com/document/d/e/2PACX-1vSpWmOp_x_5bS1AvFGMt4oQYYLQuuwUnh45x7zE1PmGO61TBPK3Wv0yU6zhjC7Z8WNn6sqPL0mAzcNH/pub');
    }
}
