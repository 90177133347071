<div class="page_login">
    <div class="page_login__header">
        <a [routerLink]="['/']">
            <img src="assets/image/common/logo_main.png" class="cobot_logo">
        </a>
    </div>
    <div class="page_login__content">
            <h1 class="page_login_box_title">로그인</h1>
            <app-login-form></app-login-form>
    </div>
</div>