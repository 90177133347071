<div class="page_forgot_password">
    <div class="page_forget_password__header">
        <a [routerLink]="['/']">
            <img src="assets/image/common/logo_main.png" class="cobot_logo">
        </a>
    </div>
    <div class="page_forgot_password__container">
        <h1 class="page_forgot_password_box_title">비밀번호 찾기</h1>
        <form>
            <div class="forgot_password_content">
                <p>가입하신 이메일 주소로 비밀번호 재설정 메일을 보내드립니다.</p>
                <div class="form_group">
                    <label class="form_label">이메일</label>
                    <input #email type="text" class="cobot_input_text" placeholder="이메일 주소를 입력해주세요.">
                </div>
                <button (click)="sendPasswordResetEmail(email.value)" class="cobot_btn_primary">이메일 전송</button>
                <div class="login_link_box">
                    <a [routerLink]="['/login']" class="login_link">로그인</a>
                </div>
            </div>
        </form>
    </div>
</div>