<div class="login_form_container">
    <form [formGroup]="formGroup" (ngSubmit)="signIn()" class="login_form">
        <div class="login_form_email_box">
            <h3 class="login_form_input_title">이메일</h3>
            <input formControlName="email" type="text" class="cobot_input_text" placeholder="이메일 주소를 입력해주세요.">
        </div>
        <div class="login_form_password_box">
            <h3 class="login_form_input_title">비밀번호</h3>
            <input formControlName="password" type="password" class="cobot_input_text" placeholder="비밀번호를 입력해주세요." autocomplete="current-password">
        </div>
        <ng-container *ngIf="errorMessage">
            <div  class="login_form_small_box">
                <small class="login_form_small">
                    {{ errorMessage }}
                </small>
            </div>
        </ng-container>
        <input type="submit" class="cobot_btn_primary" value="로그인" [disabled]="isProcessing">
    </form>
    <div class="login_form_forgot_password">
        <a [routerLink]="['/forgot_password']" class="forgot_password_link">비밀번호찾기</a>
    </div>
    <div class="login_form_sign_up_link_box">
        <label>매니저 계정이 필요하신가요?</label>
        <a [routerLink]="['/sign_up']" class="login_form_sign_up_link">회원가입</a>
    </div>
</div>