<div class="sign_up_form_container">
    <form [formGroup]="formGroup" (ngSubmit)="signUp()" class="sign_up_form">
        <ng-template #textFieldset let-title="title" let-formControlName="formControlName"
            let-formControl="formControl" let-helpText="helpText" let-placeholder="placeholder"
            let-type="type">
            <div class="sign_up_form_box">
                <h3 class="sign_up_form_input_title">{{ title }}</h3>
                <input [type]="type" class="cobot_input_text" [placeholder]="placeholder" [autocomplete]="type === 'password' ? 'new-password' : null"
                    [formControlName]="formControlName" [formControl]="formControl">
                <small class="sign_up_form_small"
                    *ngIf="formControlName !== 'password2' && formControl.hasError('required') && (formControl.dirty || formControl.touched)">
                    필수입력입니다.
                </small>
                <small class="sign_up_form_small" *ngIf="formControl.hasError('email') && (formControl.dirty || formControl.touched)">
                    이메일 형식이 올바르지 않습니다.
                </small>
                <small class="sign_up_form_small"
                    *ngIf="formControl.hasError('notSame') && (formControl.dirty || formControl.touched)">
                    {{ formControl.errors.notSame }}
                </small>
            </div>
        </ng-template>
        <ng-container [ngTemplateOutlet]="textFieldset" [ngTemplateOutletContext]="{
            title: '이메일',
            type: 'email',
            placeholder: '이메일을 입력해주세요.',
            formControlName: 'email',
            formControl: formGroup.controls['email']}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="textFieldset" [ngTemplateOutletContext]="{
            title: '이름',
            type: 'text',
            placeholder: '이름을 입력해주세요.',
            formControlName: 'name',
            formControl: formGroup.controls['name']}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="textFieldset" [ngTemplateOutletContext]="{
            title: '비밀번호',
            type: 'password',
            placeholder: '비밀번호를 입력해주세요.',
            formControlName: 'password',
            formControl: formGroup.controls['password']}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="textFieldset" [ngTemplateOutletContext]="{
            title: '비밀번호 확인',
            type: 'password',
            placeholder: '비밀번호를 한번 더 입력해주세요.',
            formControlName: 'confirmPassword',
            formControl: formGroup.controls['confirmPassword']}">
        </ng-container>
        <div class="sign_up_form_check_link_box">
            <input type="checkbox" id="agree_privacy_policy" [formControlName]="'agreePrivacyPolicy'" [formControl]="formGroup.controls['agreePrivacyPolicy']"/>
            <label for="agree_privacy_policy"><span class="privacy_policy" (click)="openPrivacyPolicy()">개인정보처리방침</span>과 <span class="terms_of_service" (click)="openTermsOfService()">이용약관</span>에 동의합니다.</label>
        </div>
        <input type="submit" class="cobot_btn_primary" value="회원가입" [disabled]="!formGroup.controls.agreePrivacyPolicy.value||isProcessing"/>
    </form>
    <ng-container *ngIf="errorMessage">
        <div  class="sign_up_form_small_box">
            <small class="sign_up_form_small">
                {{ errorMessage }}
            </small>
        </div>
    </ng-container>
    <div class="sign_up_form_log_in_link_box">
        <label>이미 계정이 있으신가요?</label>
        <a [routerLink]="['/login']" class="sign_up_form_log_in_link">로그인</a>
    </div>
</div>