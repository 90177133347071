import { Router } from '@angular/router';
import { mergeMap, map, filter, tap, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserInfo } from './../interfaces/user';
import { GroupCategory } from './../common_interfaces/GroupCategory';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    valUserInfo: UserInfo;
    userSubject$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);
    groupCategory$: BehaviorSubject<GroupCategory> = new BehaviorSubject<GroupCategory>(null);
    category: GroupCategory;

    constructor(
        private angularFireAuth: AngularFireAuth,
        private db: AngularFirestore,
        private router: Router
    ) {
        angularFireAuth.user.pipe(
            filter((user: firebase.User) => !!user),
            mergeMap((user: firebase.User) => {
                return this.setUserInfo(user);
            }),
            take(1)
        ).subscribe({
            error: (error) => {
                console.error(error);
            }
        });
    }

    get userInfo(): UserInfo {
        return this.valUserInfo;
    }

    set userInfo(userInfo: UserInfo) {
        this.valUserInfo = userInfo;
        this.userSubject$.next(userInfo);
    }

    get currentCategory(): GroupCategory {
        return this.category;
    }

    set currentCategory(category: GroupCategory) {
        this.category = category;
        this.groupCategory$.next(category);
    }

    signUp(email: string, password: string, name: string): Observable<void> {
        return from(this.angularFireAuth.createUserWithEmailAndPassword(email, password)).pipe(
            mergeMap((userCredential: firebase.auth.UserCredential) => {
                return this.db.collection('users').doc(userCredential.user.uid).set({id: userCredential.user.uid , name, email});
            })
        );
    }

    signIn(email: string, password: string): Observable<UserInfo> {
        return from(this.angularFireAuth.signInWithEmailAndPassword(email, password)).pipe(
            mergeMap((userCredential: firebase.auth.UserCredential) => {
                return this.setUserInfo(userCredential.user);
            })
        );
    }

    signOut(): void {
        this.angularFireAuth.signOut().then(() => {
            this.userInfo = null;
            this.router.navigate(['login']);
        });
    }

    setUserInfo(user: firebase.User): Observable<UserInfo> {
        return this.db.collection('users').doc(user.uid).get().pipe(
            map((doc: firebase.firestore.DocumentSnapshot) => {
                return { id : doc.id, ...doc.data() } as UserInfo;
            }),
            take(1),
            tap((userInfo: UserInfo) => {
                this.userInfo = userInfo;
            })
        );
    }

    translateErrorMessage(error: any): string {
        interface AuthError {
            code: string;
            message: string;
        }

        const authError = error as AuthError;
        if (!authError || !authError.code) {
            return error as string;
        }
        switch (authError.code) {
            case 'auth/email-already-in-use':
                return '이미 사용되고 있는 이메일 주소 입니다.';
            case 'auth/weak-password':
                return '보안에 취약한 암호입니다.';
            case 'auth/operation-not-allowed':
                return '허용되지 않는 작업입니다.';
            case 'auth/invalid-email':
                return '잘못된 이메일 주소입니다 .';
            case 'auth/wrong-password':
                return '패스워드가 맞지 않습니다.';
            case 'auth/missing-continue-uri':
                return '계속할 URL 을 제공해야 합니다.';
            case 'auth/invalid-continue-uri':
                return '계속할 URL 경로 값이 잘못되었습니다.';
            case 'auth/unauthorized-continue-uri':
                return '계속할 URL 의 도메인이 화이트리스트에 없습니다.';
            case 'auth/user-not-found':
                return '이메일에 해당하는 사용자가 존재하지 않습니다.';
            case 'auth/too-many-requests':
                return '실패한 시도가 너무 많습니다. 나중에 다시 시도하십시오.';
        }

        if (authError.message) {
            return authError.message;
        }
        return error as string;
    }
}
