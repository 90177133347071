import { Injectable } from '@angular/core';
import { CanActivateChild, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivateChild, CanActivate {
    constructor(
        private afAuth: AngularFireAuth,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
        return this.afAuth.authState.pipe(
            take(1),
            mergeMap((user: User) => {
                if (!user) {
                    return this.router.navigate(['login']);
                }
                return of(true);
            }),
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
        return this.canActivate(route, state);
    }
}

