<div class="page_sign_up">
    <div class="page_sign_up__header">
        <a [routerLink]="['/']">
            <img src="assets/image/common/logo_main.png" class="cobot_logo">
        </a>
    </div>
    <div class="page_sign_up__content">
        <h1 class="page_sign_up_box_title">회원가입</h1>
        <app-sign-up-form></app-sign-up-form>
    </div>
</div>