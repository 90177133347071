import { LoginGuard } from './auth-guard';
import { PageSignUpComponent } from './pages/page-sign-up/page-sign-up.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageForgotPasswordComponent } from './pages/page-forgot-password/page-forgot-password.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'management'
    },
    {
        path: 'login',
        component: PageLoginComponent
    },
    {
        path: 'sign_up',
        component: PageSignUpComponent
    },
    {
        path: 'forgot_password',
        component: PageForgotPasswordComponent
    },
    {
        path: 'management',
        loadChildren: () => import('./modules/management/management.module').then(m => m.ManagementModule),
        canActivateChild: [LoginGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
