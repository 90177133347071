// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebaseConfig : {
        apiKey: 'AIzaSyDHSENQzKpZQjm74Y1GWD1TXd0jl4tY9ps',
        authDomain: 'chocobot-test.firebaseapp.com',
        projectId: 'chocobot-test',
        storageBucket: 'chocobot-test.appspot.com',
        messagingSenderId: '903884407257',
        appId: '1:903884407257:web:4c48733bd5c6d583f899c8'
    },
    protocol: 'pocl.cobot-i.com',
    cobotUrl: 'https://us-central1-chocobot-test.cloudfunctions.net'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
