import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { PageSignUpComponent } from './pages/page-sign-up/page-sign-up.component';
import { SignUpFormComponent } from './components/sign-up-form/sign-up-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageForgotPasswordComponent } from './pages/page-forgot-password/page-forgot-password.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [
        AppComponent,
        PageLoginComponent,
        LoginFormComponent,
        PageSignUpComponent,
        SignUpFormComponent,
        PageForgotPasswordComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        BrowserAnimationsModule,
        MatSnackBarModule
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
