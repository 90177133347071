import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
    selector: 'app-page-forgot-password',
    templateUrl: './page-forgot-password.component.html',
    styleUrls: ['./page-forgot-password.component.scss']
})
export class PageForgotPasswordComponent implements OnInit {

    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private snackBar: MatSnackBar,
        private authService: AuthenticationService
    ) { }

    ngOnInit(): void {
    }

    sendPasswordResetEmail(email: string): void {
        if (!email) {
            return;
        }

        from(this.afAuth.sendPasswordResetEmail(email)).subscribe({
            complete: () => {
                this.openSnackBar('비밀번호 재설정을 위한 링크가 이메일로 전송되었습니다. 이메일을 확인해주세요.');
                this.router.navigate(['/login']);
            },
            error: (err) => {
                const errorMessage = this.authService.translateErrorMessage(err);
                this.openSnackBar(errorMessage);
                console.error(err);
            }
        });
    }

    openSnackBar(message: string): void {
        this.snackBar.open(message, '', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: 'notification_snack_bar'
        });
    }

}
