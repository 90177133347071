import { Router } from '@angular/router';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {

    formGroup: FormGroup;
    errorMessage: string;
    isProcessing = false;

    constructor(
        private authService: AuthenticationService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', Validators.required),
        });
    }

    signIn(): void {
        if (this.formGroup.invalid) {
            for (const form of Object.keys(this.formGroup.controls)) {
                this.formGroup.controls[form].markAsTouched();
            }
            return;
        }
        this.isProcessing = true;
        this.authService.signIn(this.formGroup.controls.email.value, this.formGroup.controls.password.value
            ).pipe(
                finalize(() => {
                    this.isProcessing = false;
                })
            ).subscribe({
            error: (err) => {
                this.errorMessage = this.authService.translateErrorMessage(err);
            },
            complete: () => {
                // TODO: 로그인 후 변경페이지 확인후 수정.
                this.router.navigate(['management']);
            }
        });
    }
}
